export const HEIGHT_BREAKPOINTS = {
    PC_FIXED_HEADER: { min: '600px' },
    MOBILE_FIXED_HEADER: { min: `1000px` },
};

export const BREAKPOINTS = {
    EXTRA_LARGE: {
        min: '1440px',
    },
    LARGE: {
        min: '1240px',
        max: '1439px',
    },
    MEDIUM_LARGE: {
        min: '1080px',
        max: '1181px',
    },
    MEDIUM: {
        min: '905px',
        max: '1239px',
    },
    SMALL: {
        min: '600px',
        max: '904px',
    },
    EXTRA_SMALL: {
        max: '599px',
    },
    USER_DROPDOWN: {
        FULL_DROPDOWN: {
            min: '768px',
        },
        ICON_ONLY_DROPDOWN: {
            max: '767px',
        },
    },
};

export const MEDIA_BREAKPOINTS = {
    EXTRA_SMALL: `@media screen and (max-width: ${BREAKPOINTS.EXTRA_SMALL.max})`,
    SMALL_MIN: `@media screen and (min-width: ${BREAKPOINTS.SMALL.min})`,
    SMALL_MAX: `@media screen and (max-width: ${BREAKPOINTS.SMALL.max})`,
    MEDIUM_MIN: `@media screen and (min-width: ${BREAKPOINTS.MEDIUM.min})`,
    MEDIUM_LARGE: `@media screen and (max-width: ${BREAKPOINTS.MEDIUM_LARGE.min})`,
    MEDIUM_LARGE_MAX: `@media screen and (max-width: ${BREAKPOINTS.MEDIUM_LARGE.max})`,
    LARGE: `@media screen and (min-width: ${BREAKPOINTS.LARGE.min}) and (max-width: ${BREAKPOINTS.LARGE.max})`,
    LARGE_MAX: `@media screen and (max-width: ${BREAKPOINTS.LARGE.max})`,
    EXTRA_LARGE: `@media screen and (min-width: ${BREAKPOINTS.EXTRA_LARGE.min})`,
    USER_DROPDOWN: {
        FULL_DROPDOWN: `@media screen and (min-width: ${BREAKPOINTS.USER_DROPDOWN.FULL_DROPDOWN.min})`,
        ICON_ONLY_DROPDOWN: `@media screen and (max-width: ${BREAKPOINTS.USER_DROPDOWN.ICON_ONLY_DROPDOWN.max})`,
    },
};

/**
 * Determines if a device has touch support.
 *
 * @returns {boolean} true if the device has touch support.
 */
export function isTouchEnabled() {
    // if Pointer Events are supported, check maxTouchPoints
    if (window.PointerEvent && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
        return true;
    }

    // no Pointer Events...
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // check for any-pointer:coarse which mostly means touchscreen
        return true;
    }

    if (!window.PointerEvent && !('maxTouchPoints' in navigator)) {
        // last resort - check for exposed touch events API / event handler
        return !!window.TouchEvent || 'ontouchstart' in window;
    }

    return false;
}
