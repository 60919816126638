import { css } from '@emotion/core';
import { BREAKPOINTS, MEDIA_BREAKPOINTS } from '../../services/breakpoints';

export const responsiveContainerCSS = css`
    display: flex;
    justify-content: space-between;

    ${MEDIA_BREAKPOINTS.MEDIUM_MIN} {
        margin-left: auto;
        margin-right: auto;
    }

    ${MEDIA_BREAKPOINTS.EXTRA_LARGE} {
        width: 80rem;
    }

    ${MEDIA_BREAKPOINTS.LARGE} {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    @media screen and (min-width: ${BREAKPOINTS.SMALL.min}) and (max-width: ${BREAKPOINTS.MEDIUM
            .max}) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
`;
